<template>
  <div class="feinongPC">
    <div class="top-content">
      <img src="/subject/feinongs/message.png" @click="openLive" alt="" />

      <img src="/subject/feinongs/open.png" @click="openLogin" alt="" />

      <img src="/subject/feinongs/login.png" @click="openLogin" alt="" />
      <div class="top-download fle">
        <div class="appCode fle down" v-show="showDown">
          <div class="codeBody">
            <div class="fle">MT5账户专用版本</div>
            <div class="codeBox">
              <div class="codeItem">
                <Qrcode :link="iosMt5DownloadUrl" :size="108" />
                <div class="fle">iOS</div>
              </div>
              <div class="codeItem">
                <Qrcode :link="androidDownloadUrl" :size="108" />
                <div class="fle">Android</div>
              </div>
            </div>
          </div>
        </div>
        <img src="/subject/feinongs/download.png" @click="downloads" alt="" />
      </div>

      <img src="/subject/feinongs/top.png" @click="toTop" alt="" />
    </div>
    <div class="tabbar" :class="navBarFixed ? 'navBarFixed' : ''">
      <img @click="goback" src="../../../../static/common/logo.png" alt="" />
    </div>
    <div class="banner">
      <div class="btn1">
        <div class="activity-name">—— 活动时间 ——</div>
        <div class="activity-time">
          2024年12月02日06:00~2024年12月07日06:00
        </div>
        <!-- <span @click="openLogin" class="btn1" alt="" /> -->
        <img
          class="deal"
          src="/subject/feinongs/button2.png"
          @click="openLogin"
          alt=""
        />
      </div>
      <div class="btn2">
        <div style="position: relative">
          <img class="deal-time" src="/subject/feinongs/time.png" alt="" />
          <!-- <span>{{ day }}天{{ hour }}时{{ min }}分{{ second }}秒</span> -->
          <span class="day">{{ day }}</span>
          <span class="hours">{{ hour }}</span>
          <span class="minute">{{ min }}</span>
          <span class="second">{{ second }}</span>
        </div>
        <div>
          <img class="deal-slider" src="/subject/feinongs/slider.png" alt="" />
          <div class="deal-flex">
            <span
              :class="index == cutter ? 'active' : ''"
              v-for="(item, index) in timeList"
              :key="index"
            >
              {{ item }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="part-teacher" v-if="teacherList && teacherList.length > 0">
      <div style="padding-top: 60px">
        <div
          class="deal-teacher"
          v-for="(item, index) in teacherList"
          :key="index"
        >
          <a class="toHref" :href="item.url"></a>
          <img style="width: 100%; height: 100%" :src="item.imgUrl" alt="" />
        </div>
      </div>
    </div>
    <div class="part-1">
      <!--<img src="/subject/feinong/tit1s.png" class="tit" alt="" />-->
      <img src="/subject/feinongs/title.png" class="tit" alt="" />
      <!-- <p class="p1">美元现金1手即领，所有用户均可领取</p> -->
      <!-- <span class="span1" @click="openPop" style=" text-decoration: underline;color: blue;"
        >活动详情</span
      > -->
      <img
        @click="openPop"
        class="span1"
        src="/subject/feinong/detail_btn.png"
        alt=""
        width="200"
      />
      <div class="part1-centent">
        <a href="javascript:;" @click="openLogin" class="red1"></a>
        <a href="javascript:;" @click="openLogin" class="red2"></a>
        <a href="javascript:;" @click="openLogin" class="red3"></a>
        <a href="javascript:;" @click="openLogin" class="red4"></a>
        <a href="javascript:;" @click="openLogin" class="red5"></a>
      </div>
      <p class="p2">机会有限，先到先得</p>
      <p class="p3">前往APP>我的>活动中心>非农周交易返赠查看活动进度</p>

      <!-- <p class="p1" style="color: #333">
        非农活动倒计时:
        <span>{{ day }}天{{ hour }}时{{ min }}分{{ second }}秒</span>
        前往<span style="color: red">APP-我的-活动中心-非农周交易返赠</span
        >查看活动进度
      </p> -->
      <div class="btnBox">
        <img
          @click="openLogin"
          src="/subject/feinongs/button.png"
          class="btns"
          alt=""
        />
        <img
          @click="openLive"
          src="/subject/feinongs/request.png"
          class="btns"
          alt=""
        />
      </div>
    </div>
    <div class="part-2">
      <!-- <img src="/subject/feinong/tit2s.png" class="tit" alt="" /> -->
      <img src="/subject/feinongs/title1.png" class="tit" alt="" />
      <img src="/subject/feinongs/background1.png" class="tits" alt="" />

      <div class="part2-centent">
        <div class="changeB">
          <!-- <div class="spBox" @mouseleave="tabLeave()">
            <span
              @mouseover="hoverLi(0)"
              :class="hoverControl == 0 ? 'active' : ''"
              >美国失业率报告</span
            >
            <span
              @mouseover="hoverLi(1)"
              :class="hoverControl == 1 ? 'active' : ''"
              >美国非农就业<Br />人数报告</span
            >
          </div> -->
          <div class="echartsBox">
            <div class="flex yearlist">
              <div
                @click="timeChange(index)"
                v-for="(item, index) in timeList1"
                :key="index"
                :class="index == current ? 'active flex1' : 'default flex1'"
              >
                {{ item }}
              </div>
            </div>
            <div class="flex timeselect">
              <div class="flex1">
                <span class="start">开始 </span>
                <el-date-picker
                  v-model="start"
                  type="date"
                  suffix-icon="el-icon-date"
                  placeholder="开始时间"
                  value-format="yyyy-MM-dd"
                  style="
                    width: 90px;
                    height: 24px;
                    border: 1px solid #cccccc;
                    border-radius: 6px;
                    font-size: 12px;
                  "
                  prefix-icon="prefix-icon-class"
                  @change="selectPicker"
                  :picker-options="pickerOptions0"
                >
                </el-date-picker>
              </div>
              <div class="flex1">
                <span class="start">结束 </span>
                <el-date-picker
                  v-model="end"
                  type="date"
                  suffix-icon="el-icon-date"
                  placeholder="结束时间"
                  value-format="yyyy-MM-dd"
                  style="
                    width: 90px;
                    height: 24px;
                    border: 1px solid #cccccc;
                    border-radius: 6px;
                    font-size: 12px;
                  "
                  prefix-icon="prefix-icon-class"
                  @change="selectPicker1"
                  :picker-options="pickerOptions1"
                >
                </el-date-picker>
              </div>
            </div>
            <div
              @mouseover="hoverLi(0)"
              id="echartsBox_one"
              class="echartsBoxItem"
            ></div>
            <!-- <div
              @mouseover="hoverLi(1)"
              id="echartsBox_two"
              v-show="hoverControl == 1"
              class="echartsBoxItem"
            ></div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="part-3">
      <!-- <img class="tit" src="/subject/feinong/tit3s.png" alt="" /> -->
      <img class="tit" src="/subject/feinongs/title2.png" alt="" />
      <div class="part3-centent">
        <div class="part-top">
          <table>
            <tr>
              <th>区域</th>
              <th>指标</th>
              <th>前值</th>
              <th>预测值</th>
              <th>公布值</th>
              <th>重要性</th>
              <th>利多 利空</th>
              <!-- <th>解读</th> -->
            </tr>
            <tr>
              <th>
                <img
                  class="country"
                  src="/subject/feinongs/country.png"
                  alt=""
                />
              </th>
              <th>加拿大7月批发销售月率(%)</th>
              <th>-0.6</th>
              <th>-1.1</th>
              <th>0.4</th>
              <th style="color: #ff3333; font-weight: 700">/</th>
              <th>利多 加元</th>
              <!-- <th>
                <img class="reader" src="/subject/feinongs/reader.png" alt="" />
              </th> -->
            </tr>

            <tr>
              <th>
                <img
                  class="country"
                  src="/subject/feinongs/country.png"
                  alt=""
                />
              </th>
              <th>加拿大7月批发销售年率(%)</th>
              <th>1</th>
              <th>/</th>
              <th>1.1</th>
              <th style="color: #ff3333; font-weight: 700">/</th>
              <th>/</th>
              <!-- <th>
                <img class="reader" src="/subject/feinongs/reader.png" alt="" />
              </th> -->
            </tr>
            <tr>
              <th>
                <img
                  class="country"
                  src="/subject/feinongs/country5.png"
                  alt=""
                />
              </th>
              <th>美国8月进口物价指数月率(%)</th>
              <th>0.1</th>
              <th>0.2</th>
              <th>0.3</th>
              <th>高</th>
              <th>利多 金银 石油利空 美元</th>
              <!-- <th>
                <img class="reader" src="/subject/feinongs/reader.png" alt="" />
              </th> -->
            </tr>
            <tr>
              <th>
                <img
                  class="country"
                  src="/subject/feinongs/country5.png"
                  alt=""
                />
              </th>
              <th>加拿大7月批发销售年率(%)</th>
              <th>1.6</th>
              <th>0.9</th>
              <th>0.8</th>
              <th>高</th>
              <th>/</th>
              <!-- <th>
                <img class="reader" src="/subject/feinongs/reader.png" alt="" />
              </th> -->
            </tr>
            <tr>
              <th>
                <img
                  class="country"
                  src="/subject/feinongs/country6.png"
                  alt=""
                />
              </th>
              <th>加拿大7月批发销售年率(%)</th>
              <th>1</th>
              <th>/</th>
              <th>1.1</th>
              <th style="color: #ff3333; font-weight: 700">/</th>
              <th>/</th>
              <!-- <th>
                <img class="reader" src="/subject/feinongs/reader.png" alt="" />
              </th> -->
            </tr>
            <tr>
              <th>
                <img
                  class="country"
                  src="/subject/feinongs/country2.png"
                  alt=""
                />
              </th>
              <th>加拿大7月批发销售年率(%)</th>
              <th>1</th>
              <th>/</th>
              <th>1.1</th>
              <th style="color: #ff3333; font-weight: 700">/</th>
              <th>/</th>
              <!-- <th>
                <img class="reader" src="/subject/feinongs/reader.png" alt="" />
              </th> -->
            </tr>
            <tr>
              <th>
                <img
                  class="country"
                  src="/subject/feinongs/country3.png"
                  alt=""
                />
              </th>
              <th>加拿大7月批发销售年率(%)</th>
              <th>1</th>
              <th>/</th>
              <th>1.1</th>
              <th style="color: #ff3333; font-weight: 700">/</th>
              <th>/</th>
              <!-- <th>
                <img class="reader" src="/subject/feinongs/reader.png" alt="" />
              </th> -->
            </tr>
          </table>
          <div class="btnBox">
            <img
              @click="openLogin"
              src="/subject/feinongs/button.png"
              class="btns"
              alt=""
            />
            <img
              @click="openLive"
              src="/subject/feinongs/request.png"
              class="btns"
              alt=""
            />
          </div>
        </div>
        <!-- <h3><i></i>黄金操作建议</h3> -->

        <div class="part-middle">
          <p class="address">—— 操作建议 ——</p>
          <!-- <img src="/subject/feinong/part3M2s.png" class="part3M2" alt="" /> -->
          <table id="advice-table">
            <tr>
              <th>品种</th>
              <th>思路</th>
              <th>支持1</th>
              <th>支持2</th>
              <th>阻力1</th>
              <th>阻力2</th>
            </tr>
            <tr>				
              <th>黄金</th>
              <th>逢低看多</th>
              <th>2550</th>
              <th>2588</th>
              <th>2673</th>
              <th>2708</th>
            </tr>
            <tr>
              <th>白银</th>
              <th>逢低看多</th>
              <th>29.14</th>
              <th>29.65</th>
              <th>31.5</th>
              <th>32.5</th>
            </tr>
          </table>
        </div>

        <div class="part-bottom">
          <p class="address">—— 交易研判 ——</p>
            
          <p class="deal-content">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;10月实际就业新增人数恐下修成负数；先前的非农就业数据再次大幅下修，8月新增就业人数从15.9万修正至7.8万人，下修8.1万人；9月就业人数则从25.4万修正至22.3万人，下修3.1万人。经修正后，8、9两月的就业人数比未修正前减少11.2万人，整体来看在过去9个月中有7个月的数据遭到下修，甚至有分析预估10月数据会被下修至负值。<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;美国劳工部的数据显示，10月的就业(11月释出)成长几乎停滞并创下2020年以来新低，原因有二：第一，10月的<br />
            数据疲软主要是受到了美国天灾飓风影响。海伦妮和米尔顿台风重创了美国劳动力市场，引起造成数据放缓，<br />
            另外最重要的原因是，尽管消息被11月市场聚焦的美国大选所覆盖，波音工人罢工所引起的制造业影响的调<br />
            查回复率。
            <br />
          
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;但以上的罢工和天然灾害属于偶发性事件，而这也可以透过我们的失业率所证实：失业率<br />
            相去不远。而美联储也有针对此事所作出评论：因为两件起因都是属于偶然性事件，所以<br />
            这不改变美联储当时11月的降息力道和影响后续12月甚至2025年的降息路径。
          </p>
        </div>

        <div class="part-bottom1">
          <p class="address" style="color: #fff">—— 非农敲黑板 ——</p>
          <p class="deal-content" style="padding-left: 320px">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;从日线级别走势图来看，自11月初黄金再度缔造历史新高后，整体行情迅速展开修正，由于美联储降息周期<br />
            的强度将因为特朗普1月20日上任和她的关税贸易政策所以预期引起的通膨而对金价有很大的变数。目前市场还<br />
            另外市场较大的变因如俄乌局势和巴以战争持续升温，这也为金价带来充分的发展空间。未就数据为未来的降息<br />
            路径进行充分定价，可以在拉回2700美元/盎司整数大关，或着是再次突破历史新高与2750/2800这种整数大关目<br />
            标后，持续做多！<br/>
          </p>
          <p
            class="deal-content"
            style="color: #ff3333; padding-left: 360px; font-size: 13px"
          >
            【以上内容仅代表个人观点，不代表本平台立场，仅供参考，并不构成任何操作建议。坚定自己的思路，做好相应风险控制。】
          </p>
        </div>
      </div>
      <!-- <img
        @click="openLogin"
        src="/subject/feinong/btnf.png"
        class="btn1"
        alt=""
      /> -->
    </div>
    <img class="part-bg" src="/subject/feinongs/title3.png" alt="" />
    <div class="part-4" ref="targetElement">
      <div class="part4-centent">
        <div class="partRight">
          <img src="/subject/feinongs/advantage.png" class="advantage" alt="" />

          <div class="appCode">
            <p class="appcode-title">
              —— 下载红狮启富app，随时掌握非农动态! ——
            </p>
            <div class="app-box">
              <div class="codeBody">
                <div>MT4账户专用版本</div>
                <div class="codeBox">
                  <div class="codeItem">
                    <Qrcode
                      :link="iosMt4DownloadUrl"
                      :size="126"
                      :iconSize="30"
                    />
                    <div>iOS</div>
                  </div>
                  <div class="codeItem">
                    <Qrcode :link="androidMt4DownloadUrl" :size="126" />
                    <div>Android</div>
                  </div>
                </div>
              </div>
              <div class="codeBody">
                <div>MT5账户专用版本</div>
                <div class="codeBox">
                  <div class="codeItem">
                    <Qrcode :link="iosMt5DownloadUrl" :size="126" />
                    <div>iOS</div>
                  </div>
                  <div class="codeItem">
                    <Qrcode :link="androidDownloadUrl" :size="126" />
                    <div>Android</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="serveBox">
            <div class="serveBox-item" style="margin-top: 16px">
              <p>1、MT4版本用MT4账户，MT5版本用MT5账户，两者不互通；</p>
            </div>
            <div class="serveBox-item">
              <p>
                2、本平台2024年8月31日10：00之前开立的账户，均为MT4账户。之后开立的账户均为MT5账户。如果
              </p>
              <p>
                不确定，请联系<span class="serveBox-serve" @click="openLive"
                  >在线客服</span
                >查询。
              </p>
            </div>
            <div class="serveBox-title">
              <p>
                红狮专注伦敦金/银15年，只为给您这1秒的交易，带来更专业的体验
              </p>
            </div>
          </div>
        </div>
        <!-- <div class="partRight"></div> -->
      </div>
      <!-- <p class="p2">
        红狮专注伦敦金/银15年，只为给您这1秒的交易，带来更专业的体验
      </p> -->
    </div>
    <!-- <contactusVue></contactusVue> -->
    <div class="popup" v-if="controlPop">
      <span @click="closePop" class="guanbi1">X</span>
      <ul class="popContent">
        <li>
          <span>活动对象：</span>
          本公司所有新老用户
        </li>
        <li>
          <span>活动交易时间：</span>
          <!-- {{ activityTime.FNStartTime }}—{{ activityTime.FNEndTime }} -->
          2024年12年02日06:00—2024年12年07日06:00

        </li>
        <li>
          <span>兑奖截止时间：</span>
          <!-- {{ activityTime.FNExchangeEndTime }}（逾期失效） -->
          2024年12月25日00:00（逾期失效）
        </li>
        <li>
          <span>活动规则：</span><br />
          活动期间内，凡在本公司的APP或MT交易平台交易伦敦金、伦敦银等指定品种，完成指定交易手数，即可兑换相应美元现金奖励，交易时间结束后，统一开始兑换，限兑奖一次；
          <br />
        </li>
        <li>
          <span>交易奖励：</span><br />
          <table>
            <tr>
              <th>赠送条件</th>
              <th>美元现金奖励</th>
            </tr>
            <tr>
              <th>≥1手</th>
              <th>1美元</th>
            </tr>
            <tr>
              <th>≥5手</th>
              <th>10美元</th>
            </tr>
            <tr>
              <th>≥10手</th>
              <th>25美元</th>
            </tr>
            <tr>
              <th>≥50手</th>
              <th>150美元</th>
            </tr>
            <tr>
              <th>≥100手</th>
              <th>350美元</th>
            </tr>
            <tr>
              <th>≥500手</th>
              <th>1750美元</th>
            </tr>
          </table>
        </li>
        <li>
          <span>领取方式：</span><br />
          1.非农奖励在“APP”→“我的”→“活动中心”→“非农活动”中领取；<br />
          2.活动只计算非农周交易手数，奖励会在非农交易周之后开启兑换；<br />
          3.奖励后的美元可直接提现，亦可继续用于交易；<br />
          4.美元现金奖励需在兑奖截止日期前领取，逾期失效； <br />
        </li>
        <li>
          <span>注意事项</span><br />
          1.客户参与活动需在本公司开立真实交易账户，为保证资金安全，参与该活动的新用户，在初始L1层级，账户强平比例上调至50%，升级后自动恢复为20%；<br />
          2.本活动指定品种详情可咨询客服，交易手数计算以开平仓为准；<br />
          3.各账户层级用户均可参加本活动，美元现金奖励将依据活动交易期间的最终开平仓手数发放；<br />
          4.本公司保留随时修订、暂停、终止本活动及任何相关规则条款之权利及其解释权。<br />
        </li>
      </ul>
    </div>
    <div class="window-mask" v-if="controlPop"></div>
  </div>
</template>

<script>
import ContactusVue from "../../Contactus.vue";
import { getFeinongTime } from "../../../../api/info";
// import { getSYLApi, getFNApi } from "../../../../api/subject/feinong";
import {
  getTeacherList,
  getTime,
  getSYLApi,
  getFNApi,
} from "../../../../api/subject/hdtime";
import { mapState } from "vuex";
import Qrcode from "../../QrCode/index.vue";
export default {
  name: "newfeinongPC",
  components: {
    ContactusVue,
    Qrcode,
  },
  data() {
    return {
      timer: Function,
      controlPop: false,
      hoverControl: 0,
      timeEnd: "2tian",
      curStartTime: "2024-07-06 06:00:00",
      day: "00",
      hour: "00",
      min: "00",
      second: "00",
      screenWidth: null,
      navBarFixed: false,
      scroll: "",
      activityTime: {
        FNStartTime: null,
        FNEndTime: null,
        FNExchangeEndTime: null,
      },
      form: {
        languageIdentification: "1",
        type: "61",
        brandId: "1",
        region: "1",
      },
      form1: {
        key: "FNReleaseDate",
      },
      arrx1: [],
      fnX: [],
      timeList: [
        "05月03日",
        "06月07日",
        "07月05日",
        "08月02日",
        "09月04日",
        "10月04日",
        "11月01日",
        "12月06日",
        "01月05日",
        "02月02日",
        "03月08日",
        "04月05日",
      ],
      timeList1: ["半年", "一年", "两年", "三年"],
      current: 1,
      forms: {
        startTime: "2022-05-05",
        endTime: "2023-05-05",
      },
      // 开始时间、
      start: "2022-05-05",
      // 结束时间
      end: "2023-05-05",
      teacherList: [],
      showDown: false,
      cutter: 1,
      pickerOptions0: {
        disabledDate: (time) => {
          if (this.end != "") {
            let curDate = new Date().getTime();
            let three = 3 * 365 * 24 * 3600 * 1000;
            let threeMonths = curDate - three;
            return (
              time.getTime() > Date.now() ||
              time.getTime() > this.end ||
              time.getTime() < threeMonths
            );
          } else {
            return time.getTime() > Date.now();
          }
        },
      },
      pickerOptions1: {
        disabledDate: (time) => {
          return time.getTime() < this.start || time.getTime() > Date.now();
        },
      },
    };
  },
  computed: {
    ...mapState("common", {
      androidMt4DownloadUrl: "androidMt4DownloadUrl",
      androidDownloadUrl: "androidDownloadUrl",
      iosMt4DownloadUrl: "iosMt4DownloadUrl",
      iosMt5DownloadUrl: "iosMt5DownloadUrl",
    }),
  },
  methods: {
    getNowFormatDate() {
      let date = new Date(),
        year = date.getFullYear(), //获取完整的年份(4位)
        month = date.getMonth() + 1, //获取当前月份(0-11,0代表1月)
        strDate = date.getDate(); // 获取当前日(1-31)
      if (month < 10) month = `0${month}`; // 如果月份是个位数，在前面补0
      if (strDate < 10) strDate = `0${strDate}`; // 如果日是个位数，在前面补0

      return `${year}-${month}-${strDate}`;
    },
    // 软件下载
    downloads() {
      this.showDown = !this.showDown;
    },
    // 返回上级页面
    goback() {
      window.location.href = sessionStorage.getItem("craig");
    },
    // 控制顶部的定位
    handleScroll() {
      this.scroll =
        document.documentElement.scrollTop + document.body.scrollTop; //获取滚动后的高度
      if (this.scroll > 90) {
        this.navBarFixed = true;
      } else {
        this.navBarFixed = false;
      }
    },
    openPop() {
      this.controlPop = true;
    },
    closePop() {
      this.controlPop = false;
    },
    openUser() {
      window.open("https://www.rlcproltd.net/uc/signUp/real");
    },
    openLogin() {
      window.open("https://www.rlcproltd.net/uc/login");
    },
    openLive() {
      window.open(
        this.$parent.live800UrlUc
        // "https://chat32.live800.com/live800/chatClient/chatbox.jsp?companyID=215184&configID=35519&jid=5377035377"
      );
    },
    hoverLi(attr) {
      clearInterval(this.timer);
      this.hoverControl = attr;
    },
    toTop() {
      document.documentElement.scrollTop = 0;
    },
    scrollToTarget() {
      const targetElement = this.$refs.targetElement;
      const targetPosition = targetElement.offsetTop;
      window.scrollTo({
        top: targetPosition,
        behavior: "smooth",
      });
    },

    countTime() {
      // 获取当前时间
      let date = new Date();
      let now = date.getTime();
      // 设置截止时间
      let endDate = new Date(this.curStartTime); // this.curStartTime需要倒计时的日期
      let end = endDate.getTime();

      // 时间差
      let leftTime = end - now;
      // 定义变量 d,h,m,s保存倒计时的时间

      if (leftTime >= 0) {
        // 天
        let day = Math.floor(leftTime / 1000 / 60 / 60 / 24);
        // 三元表达式判断不满10前面加0
        this.day = day < 10 ? "0" + day : day;
        // 时
        let h = Math.floor((leftTime / 1000 / 60 / 60) % 24);
        this.hour = h < 10 ? "0" + h : h;
        // 分
        let m = Math.floor((leftTime / 1000 / 60) % 60);
        this.min = m < 10 ? "0" + m : m;
        // 秒
        let s = Math.floor((leftTime / 1000) % 60);
        this.second = s < 10 ? "0" + s : s;
        // console.log(this.day, this.hour, this.min, this.second);
      } else {
        this.day = "00";
        this.hour = "00";
        this.min = "00";
        this.second = "00";
      }
      // 等于0的时候不调用
      if (
        Number(this.hour) === 0 &&
        Number(this.day) === 0 &&
        Number(this.min) === 0 &&
        Number(this.second) === 0
      ) {
        return;
      } else {
        // 递归每秒调用countTime方法，显示动态时间效果,
        setTimeout(this.countTime, 1000);
      }
    },
    // tabLeave鼠标离开事件
    tabLeave() {
      this.timer = setInterval(this.updateDiv, 3000);
    },
    // 切换rollBox的方法
    updateDiv() {
      if (this.hoverControl == 0) {
        this.hoverControl = this.hoverControl + 1;
      } else if (this.hoverControl == 1) {
        this.hoverControl = 0;
      }
    },
    formatterHover(params) {
      return (
        '<span style="padding-left:5px;height:30px;line-height:30px;display: inline-block;">' +
        params[0].axisValue +
        "<br>今值: " +
        params[0].data +
        "</span>"
      );
    },
    // 选择时间
    timeChange(index) {
      this.current = index;
      // 获取当前日期
      const currentDate = new Date();

      // 获取半年前的时间
      if (index == 0) {
        const sixMonthsAgo = new Date();
        sixMonthsAgo.setMonth(currentDate.getMonth() - 6);
        this.start = this.getSelectData(sixMonthsAgo);
        this.forms.startTime = this.getSelectData(sixMonthsAgo);
      }

      // 获取一年前的时间
      if (index == 1) {
        const oneYearAgo = new Date();
        oneYearAgo.setFullYear(currentDate.getFullYear() - 1);
        this.start = this.getSelectData(oneYearAgo);
        this.forms.startTime = this.getSelectData(oneYearAgo);
      }

      // 获取2年前的时间
      if (index == 2) {
        const twoYearAgo = new Date();
        twoYearAgo.setFullYear(currentDate.getFullYear() - 2);
        console.log(this.getSelectData(twoYearAgo));
        this.start = this.getSelectData(twoYearAgo);
        this.forms.startTime = this.getSelectData(twoYearAgo);
      }

      // 获取3年前的时间
      if (index == 3) {
        const threeYearAgo = new Date();
        threeYearAgo.setFullYear(currentDate.getFullYear() - 3);
        console.log(this.getSelectData(threeYearAgo));
        this.start = this.getSelectData(threeYearAgo);
        this.forms.startTime = this.getSelectData(threeYearAgo);
      }
      this.getJoblist();
      this.lostJoblist();
    },
    // 获取选择时间
    getSelectData(data) {
      // 将日期对象转换为自定义日期格式
      const formatDateString = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
      };
      return formatDateString(data);
    },
    //  选择开始时间
    selectPicker(e) {
      console.log(e);
      var startNum = parseInt(e.replace(/-/g, ""), 10);
      var endNum = parseInt(this.end.replace(/-/g, ""), 10);
      if (startNum > endNum) {
        this.$message("开始时间不能大于结束时间！");
        return false;
      }
      this.current = null;
      this.start = e;
      this.forms.startTime = e;
      this.getJoblist();
      this.lostJoblist();
    },
    //  选择结束时间
    selectPicker1(e) {
      console.log(e);
      var startNum = parseInt(this.start.replace(/-/g, ""), 10);
      var endNum = parseInt(e.replace(/-/g, ""), 10);
      if (startNum > endNum) {
        this.$message("开始时间不能大于结束时间！");
        return false;
      }
      this.current = null;
      this.end = e;
      this.forms.endTime = e;
      this.getJoblist();
      this.lostJoblist();
    },
    // 非农就业
    getJoblist() {
      getFNApi(this.forms).then((res) => {
        var arr_X = [];
        var arr_Y = [];
        var arr = res.dt;
        if (arr && arr.length > 0) {
          for (let i = arr.length - 1; i >= 0; i--) {
            arr_X.push(arr[i].date.slice(0, 10));
            arr_Y.push(arr[i].announcement);
          }
          this.arrx1 = arr_Y;
        } else {
          this.arrx1 = [];
        }
      });
    },
    // 失业人数
    lostJoblist() {
      getSYLApi(this.forms).then((res) => {
        var arr_X = [];
        var arr_Y = [];
        var arr = res.dt;
        if (arr && arr.length > 0) {
          for (let i = arr.length - 1; i >= 0; i--) {
            console.log(arr[i]);
            arr_X.push(arr[i].date.slice(0, 10));
            arr_Y.push(arr[i].announcement);
          }
        } else {
          arr_Y = [];
        }

        let myChart = this.$echarts.init(
          document.getElementById("echartsBox_one")
        );
        console.log("非农数据---------", this.arrx1);
        console.log("非农数据1---------", arr_Y);
        let option = {
          // legend: {
          //   textStyle: { fontSize: 16 },
          // },
          legend: {
            data: ["美国失业率", "美国非农就业人数"],
          },
          tooltip: {
            trigger: "axis",
            triggerOn: "mousemove",
            enterable: true, //鼠标是否可进入提示框浮层中
            formatter: this.formatterHover, //修改鼠标悬停显示的内容
          },
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: arr_X,
            // axisLabel: {
            //   interval: 1
            // },
          },
          yAxis: {
            type: "value",
            name: "今值(万)",
          },
          dataZoom: [
            {
              show: true,
              realtime: true,
              start: 65,
              end: 85,
            },
            {
              type: "inside",
              realtime: true,
              start: 65,
              end: 85,
            },
          ],
          series: [
            {
              name: "美国失业率",
              type: "line",
              symbol: "none",
              sampling: "lttb",
              lineStyle: {
                width: 1,
                color: "#FF3333",
              },
              emphasis: {
                focus: "series",
              },

              // prettier-ignore
              data: arr_Y,
            },
            {
              name: "美国非农就业人数",
              type: "line",
              symbol: "none",
              sampling: "lttb",

              lineStyle: {
                width: 1,
                color: "#3333FF",
              },
              emphasis: {
                focus: "series",
              },

              // prettier-ignore
              data: this.arrx1,
            },
          ],
        };
        myChart.setOption(option);
      });
    },
  },
  created() {
    const currentDate = new Date();
    const oneYearAgo = new Date();
        oneYearAgo.setFullYear(currentDate.getFullYear() - 1);
        this.start = this.getSelectData(oneYearAgo);
        this.forms.startTime=this.getSelectData(oneYearAgo);
    this.end=this.getNowFormatDate()
    this.forms.endTime=this.getNowFormatDate()

    this.$emit("controlShow", true);
    // if(document.body.clientWidth<500){
    //   this.$router.push({
    //     name:'autumnOctWap',
    //   })
    // }
    // 判断是从A前还是A后跳转过来
    if (!sessionStorage.getItem("craig")) {
      if (!this.$route.query.craig) {
        sessionStorage.setItem("craig", window.location.origin);
      } else {
        sessionStorage.setItem("craig", this.$route.query.craig);
      }
    }
    // 每4秒自动切换rollBox
    this.timer = setInterval(this.updateDiv, 3000);
    // 获取 活动 时间
    getFeinongTime().then((res) => {
      this.activityTime = res.data;
    });
    // 获取倒计时
    getTime(this.form1).then((res) => {
      console.log("倒计时数据", res);
      this.curStartTime = res.Data;
      let list = this.curStartTime.split("");
      list[7] = "月";
      list[10] = "日";
      console.log("时间", list);
      let time = list.join("").substring(5, 11);

      this.timeList.map((item, index) => {
        if (item == time) {
          this.cutter = index;
        }
      });
      this.countTime();
    });
    // 讲师列表
    getTeacherList(this.form).then((res) => {
      console.log("讲师数据--------------", res);
      this.teacherList = res.Data;
    });
    this.getJoblist();
    this.lostJoblist();
  },
  mounted() {
    // this.countTime();
    //获取屏幕尺寸
    this.screenWidth = document.body.clientWidth;
    window.addEventListener("scroll", this.handleScroll);
    window.onresize = () => {
      //屏幕尺寸变化
      return (() => {
        this.screenWidth = document.body.clientWidth;
      })();
    };
  },
  watch: {
    screenWidth: function (n) {
      if (n <= 500) {
        this.$router.push({
          name: "newfeinongWap",
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-input__inner {
  height: 24px !important;
  padding: 0 10px !important;
}
img {
  display: block;
}
.top-content {
  width: 71px;
  height: 460px;
  border: 1px solid #001bea;
  border-radius: 35px;
  position: fixed;
  top: 166px;
  right: 20px;
  background: url("/subject/feinongs/float.png") no-repeat center;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 88;
  img {
    width: 54px;
    height: 79px;
    margin-bottom: 15px;
  }
}
.top-download {
  position: relative;
  .fle {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .down {
    position: absolute;
    right: 132%;
    top: 0;
    background-color: #ffa343;
    padding: 20px;
  }
}
.downs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
}
.tabbar {
  background-color: #ffffff;
  padding: 10px 30px;

  img {
    width: 130px;
  }
}

.navBarFixed {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 999;
}

.feinongPC {
  width: 100%;
  background: url("/subject/feinongs/bg.png") no-repeat center;
  background-size: 100% 100%;

  .banner {
    width: 100%;
    background: url("/subject/feinongs/banners.png") no-repeat center;
    background-size: cover;
    height: 789px;
    position: relative;
    .deal {
      width: 288px;
      height: 52px;
      margin-top: 40px;
    }
    .deal-time {
      width: 1200px;
      height: 216px;
    }
    .deal-slider {
      width: 1200px;
      height: 24px;
      // margin-top: 30px;
    }
    .active {
      color: #ffffff;
    }
    .deal-flex {
      display: flex;
      align-items: center;
      justify-content: space-around;
      font-size: 14px;

      font-weight: 400;
      text-align: center;
      color: #585b96;
    }
    .btn1 {
      position: absolute;
      // width: 314px;
      height: 84px;
      left: 46%;
      top: 442px;
      cursor: pointer;
      margin-left: -160px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .btn2 {
      position: absolute;

      left: 360px;
      top: 632px;
      cursor: pointer;

      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 36px;

      font-weight: 400;
      text-align: center;
      color: #ffffff;

      .day {
        position: absolute;
        left: 49%;
        top: 40%;
      }
      .hours {
        position: absolute;
        left: 57%;
        top: 40%;
      }
      .minute {
        position: absolute;
        left: 66%;
        top: 40%;
      }
      .second {
        position: absolute;
        left: 74%;
        top: 40%;
      }
    }
    .activity-name {
      width: 186px;
      height: 20px;
      font-size: 20px;
      font-weight: 400;
      text-align: center;
      color: #ffffff;
      text-shadow: 0px 0px 73.73px 0px rgba(15, 21, 63, 0.8);
    }
    .activity-time {
      margin-top: 16px;
      font-size: 20px;
      font-family: Microsoft YaHei, Microsoft YaHei-Regular;
      font-weight: 400;
      text-align: center;
      color: #ffffff;
      text-shadow: 0px 0px 73.73px 0px rgba(15, 21, 63, 0.8);
    }
  }
  .part-teacher {
    margin-left: 60px;
    margin-top: 76px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .deal-teacher {
      width: 1200px;
      height: 522px;
      // margin: 60px 0;
      // margin-top: 30px;
      position: relative;
    }
    .toHref {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
      cursor: pointer;
      width: 1200px;
      height: 522px;
    }
  }
  .part-1 {
    width: 100%;
    padding-top: 60px;

    .tit {
      display: block;
      margin: 0 auto;
      width: 636px;
    }

    .p1 {
      color: #e26f08;
      font-size: 30px;
      text-align: center;
      margin: 20px 0;
    }
    .p2 {
      font-size: 20px;

      font-weight: 700;
      text-align: center;
      color: #ffd9aa;
      text-shadow: 0px 0px 36px 0px rgba(0, 0, 0, 0.28);
    }
    .p3 {
      font-size: 16px;

      font-weight: 400;
      text-align: center;
      color: #ffffff;
    }
    .span1 {
      display: block;
      margin: 0 auto;
      text-align: center;
      color: #e26f08;
      font-size: 30px;
      cursor: pointer;
    }

    .part1-centent {
      width: 1519px;
      height: 532px;
      background: url("/subject/feinongs/deal.png") no-repeat;
      background-size: cover;
      display: block;
      margin: 0 auto;

      position: relative;
      right: 104px;
      .red1 {
        position: absolute;
        width: 110px;
        height: 55px;
        top: 236px;
        left: 374px;
        cursor: pointer;
      }

      .red2 {
        position: absolute;
        width: 110px;
        height: 55px;
        top: 236px;
        right: 369px;
        cursor: pointer;
      }

      .red3 {
        position: absolute;
        width: 110px;
        height: 55px;
        top: 491px;
        left: 182px;
        cursor: pointer;
      }

      .red4 {
        position: absolute;
        width: 110px;
        height: 55px;
        top: 491px;
        left: 614px;
        cursor: pointer;
      }

      .red5 {
        position: absolute;
        width: 110px;
        height: 55px;
        top: 491px;
        right: 177px;
        cursor: pointer;
      }
    }
  }
  .btnBox {
    // width: 1200px;
    display: block;
    margin: 0 auto;
    text-align: center;
    // margin-top: 20px;
    // margin-bottom: 20px;

    img {
      display: inline-block;
      cursor: pointer;
      width: 273px;
      height: 129px;
    }

    // img:nth-child(1) {
    //   margin-right: 98px;
    // }
  }
  .part-2 {
    width: 100%;
    padding-top: 50px;
    // background: url('/subject/feinong/part-2.jpg') no-repeat;
    background-size: 100% 100%;
    .tit {
      display: block;
      margin: 0 auto;
      width: 636px;
      height: 103px;
    }
    .tits {
      display: block;
      margin: 0 auto;
      width: 1035px;
      height: 494px;
    }

    .p1 {
      color: #000;
      font-size: 30px;
      text-align: center;
      margin: 20px 0;
    }

    .part2tit {
      width: 100%;
    }

    .part2-centent {
      width: 1003px;
      // height: 883px;

      box-sizing: border-box;
      margin: 0 auto;
      margin-top: 30px;
      padding: 50px 0px;
      .tits {
        display: block;
        margin: 0 auto;
      }
      p {
        color: #000;
        font-size: 28px;
        margin-bottom: 25px;

        i {
          width: 25px;
          height: 22px;
          display: inline-block;
          background: url("/subject/feinong/icon_l.png") no-repeat;
          background-size: cover;
          margin: 0 10px;
        }
      }

      ul {
        justify-content: center;
        align-items: center;

        li {
          width: 1200px;
          color: #000;
          font-size: 28px;
          list-style-type: disc;
          margin-bottom: 40px;
        }
      }

      .changeB {
        width: 1003px;
        position: relative;
        display: flex;

        .spBox {
          width: 240px;
          display: inline-block;
        }

        .spBox span {
          background: #fff;
          color: #efaa05;
          font-size: 28px;
          display: block;
          text-align: center;
          height: 164px;
          box-sizing: border-box;
          padding-top: 43px;
        }

        .spBox span:nth-child(1) {
          border-top-left-radius: 5px;
        }

        .spBox span:nth-child(2) {
          border-bottom-left-radius: 5px;
        }

        .spBox span.active {
          background: linear-gradient(to top right, #e4760b, #fdab5f);
          color: #fff;
        }

        .echartsBox {
          padding-top: 35px;
          width: 1003px;
          height: 400px;
          background-color: #fff;
          background: url("/subject/feinongs/border3.png") no-repeat;
          background-size: 100% 100%;
          position: relative;
          .yearlist {
            width: 180px;
            position: absolute;
            top: 6%;
            left: 4%;
            z-index: 6666;
          }
          .start {
            margin-right: 10px;
          }
          .timeselect {
            width: 280px;
            position: absolute;
            top: 6%;
            right: 4%;
            font-size: 12px;
            z-index: 6666;
          }
          .flex1 {
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .flex {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
          .default {
            font-size: 12px;
            font-weight: 400;
            text-align: center;
            color: #999999;
            width: 41px;
            height: 24px;
            background: #fff0f0;
            border-radius: 6px;
          }
          .active {
            font-size: 12px;
            font-weight: 400;
            text-align: center;
            color: #ff3333;
            width: 41px;
            height: 24px;
            background: #ffe0e0;
            border-radius: 6px;
          }
          .echartsBoxItem {
            width: 1013px;
            height: 328px;
          }
        }

        img {
          position: absolute;
          right: -22px;
          top: 0;
        }
      }
    }
  }

  .part-3 {
    padding-top: 60px;
    width: 100%;
    padding-bottom: 60px;
    .tit {
      display: block;
      margin: 0 auto;
      margin-bottom: 30px;
      width: 636px;
      height: 103px;
    }

    .part3-centent {
      display: block;
      margin: 0 auto;
      width: 1333px;
      // height: 1505px;

      padding-top: 30px;
      box-sizing: border-box;
      padding-left: 174px;
      .address {
        font-size: 20px;

        font-weight: 700;
        text-align: center;
        color: #dc9948;
        text-shadow: 0px 0px 61.2px 0px rgba(15, 21, 63, 0.8);
      }
      h3 {
        font-size: 40px;
        color: #efaa05;

        i {
          display: inline-block;
          width: 5px;
          height: 24px;
          margin-right: 12px;
          background: #efaa05;
          border-radius: 5px;
        }
      }

      //
      .part-top {
        width: 1003px;
        // height: 608px;
        padding: 32px 46px;
        background: url("/subject/feinongs/border3.png") no-repeat;
        background-size: 100% 100%;
        box-sizing: border-box;
      }

      // 操作建议
      .part-middle {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 1000px;
        background: url("/subject/feinongs/border.png") no-repeat;
        background-size: 100% 100%;
        box-sizing: border-box;
        padding: 20px 0 30px 0;
        margin: 20px 0 22px 0;
      }

      // 建议研判
      .part-bottom {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 1000px;
        // height: 396px;
        background: url("/subject/feinongs/border1.png") no-repeat;
        background-size: 100% 100%;
        padding: 26px 52px 25px 43px;
        box-sizing: border-box;
        .deal-content {
          width: 905px;
          font-size: 16px;

          font-weight: 400;

          color: #ffffff;
          line-height: 32px;
        }
      }

      .part-bottom1 {
        margin-top: 22px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 1000px;
        // height: 396px;
        background: url("/subject/feinongs/background.png") no-repeat;
        background-size: 100% 100%;
        padding: 26px 52px 25px 43px;
        box-sizing: border-box;
        .deal-content {
          width: 905px;
          font-size: 16px;

          font-weight: 400;

          color: #ffffff;
          line-height: 32px;
        }
      }
      #advice-table {
        width: 80%;
        tr {
          background: linear-gradient(
              0deg,
              rgba(0, 19, 171, 0.16) 0%,
              rgba(0, 25, 243, 0.16) 50%,
              rgba(0, 20, 166, 0.16) 100%
            ),
            rgba(255, 255, 255, 0);
          // border: 1px solid #0017cb;
          border-left: 1px solid #0017cb;
          border-right: 1px solid #0017cb;
          th {
            text-align: center;
            font-weight: 500;
            line-height: 50px;

            padding: 5px;
            color: #fff;
            font-size: 14px;

            border-bottom: 1px solid #0017cb;
          }
        }
        tr:nth-child(1) {
          th {
            background: linear-gradient(-90deg, #ffae59 0%, #ffcb7e 100%),
              #ffffff;
            background-color: #ff2c41;
            line-height: 48px;
            font-weight: 700;
            text-align: center;
            color: #84490d;
          }
        }
      }
      table {
        width: 100%;
        // border: 2px solid #e28172;
        border-radius: 16px;
        // background-color: #fffbee;
        border-collapse: collapse;
        .country {
          width: 27px;
          height: 18px;
        }
        .reader {
          width: 16px;
          height: 16px;
        }
        tr {
          th {
            text-align: center;
            font-weight: 500;
            line-height: 60px;
            border-bottom: 1px solid #e5e5e5;
            // border-right: 1px solid #c4693b;
            padding: 5px;
            color: #333;
            font-size: 14px;
          }

          th:last-child {
            // border-right: 1px solid transparent;
          }
        }

        tr:nth-child(1) {
          th {
            // width: 35%;
            font-size: 14px;
            font-weight: 600;
            color: #fff;
            background-color: #ff2c41;
            line-height: 38px;
            // border: 0;
          }
        }
        tr:nth-child(4),
        tr:nth-child(5) {
          th {
            line-height: 48px;
            font-weight: 700;
            text-align: center;
            color: #ff3333;
          }
        }
        tr:last-child {
          th {
            border-bottom: none;
          }
        }
      }

      .part3M2 {
        width: 93%;
        margin-bottom: 10px;
        border-radius: 16px;
      }
      b {
        color: #efaa05;
      }
      p {
        font-size: 24px;
        color: #666666;
        line-height: 48px;
        width: 1200px;
      }

      .sp {
        font-size: 14px;
        opacity: 0.5;
        line-height: 30px;
      }
    }

    .btn1 {
      display: block;
      margin: 0 auto;
      margin-top: 52px;
      cursor: pointer;
    }
  }
  .part-bg {
    display: block;
    margin: 0 auto;
    // margin-bottom: 60px;
    width: 636px;
    height: 103px;
  }
  .part-4 {
    padding-top: 100px;
    padding-bottom: 64px;
    // background: url("/subject/feinong/part4.jpg") no-repeat;
    background: url("/subject/feinongs/bottom.png") no-repeat;
    // background-size: cover;
    background-size: 100% 100%;
    width: 100%;
    width: 1920px;
    height: 673px;

    .advantage {
      display: block;
      margin: 0 auto;
      margin-bottom: 20px;
      width: 617px;
      height: 152px;
    }

    padding-top: 60px;

    .part4-centent {
      width: 1300px;
      margin: 0 auto;
      margin-bottom: 100px;

      .partLeft {
        display: inline-block;
        vertical-align: top;
        padding-top: 160px;
      }

      .phone {
        display: inline-block;
        vertical-align: top;
        margin-right: 30px;
      }

      .partRight {
        display: inline-block;
        margin-top: 30px;
        margin-left: 500px;
        p {
          color: #e4760b;
          font-size: 24px;
          line-height: 55px;
          margin-bottom: 50px;
          text-align: center;
        }

        .ewm {
          width: 258px;
          height: 258px;
          margin: 0 auto;
        }
      }

      ul {
        li {
          width: 356px;
          height: 74px;
          text-align: center;
          line-height: 74px;
          color: #e4760b;
          font-size: 26px;
          margin-bottom: 35px;
          margin-left: 40px;
          font-weight: bold;
          border: 1px solid #e4760b;
          border-radius: 10px;
          display: inline-block;
        }
      }
      .appCode {
        margin-left: 40px;
        display: flex;
        // justify-content: center;
        flex-direction: column;
        text-align: center;
        background: url("/subject/feinongs/border4.png") no-repeat;
        background-size: 100% 100%;
        width: 615px;
        .app-box {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        // height: 302px;
        padding: 30px;
        .appcode-title {
          font-size: 14px;

          font-weight: 400;
          text-align: center;
          color: #ff3333;
          line-height: 28px;
          margin-bottom: 13px;
        }
        .codeBody {
          > div:first-child {
            font-weight: 700;
            margin-bottom: 20px;
            font-size: 20px;
          }
          .codeBox {
            display: flex;
            justify-content: center;
            margin-bottom: 15px;
            margin-right: 30px;
            .codeItem {
              &:first-child {
                margin-right: 15px;
              }
              .canvasCode {
                border-radius: 6px;
              }
              div {
                line-height: 32px;
                // line-height: 48px;
                // background: #e4760b;
                background: linear-gradient(150deg, #ff2c41 0%, #ff1232 100%),
                  #ff3333;
                color: #fff;
                border-radius: 4px;
                margin-top: 6px;
                // font-size: 20px;
                font-size: 14px;
              }
            }
          }
        }
      }
      .serveBox {
        margin-left: 40px;
        &-serve {
          color: red;
          border-bottom: 1px solid red;
          cursor: pointer;
        }
        &-item {
          p {
            text-align: left;
            margin: 0;
            line-height: normal;
            // font-size: 16px;
            // color: #2d2d2d;
            font-size: 14px;
            color: #fff;
            text-indent: 1.5em; /* 设置整体缩进 */
            &:first-child {
              text-indent: 0;
            }
          }
        }
        &-title {
          margin-top: 16px;
          p {
            text-align: left;
            margin: 0;
            line-height: normal;

            font-size: 16px;
            color: #ff3333;
            // text-indent: 1.5em; /* 设置整体缩进 */
            font-size: 16px;

            font-weight: 700;

            line-height: 20px;
          }
        }
      }
    }

    .p2 {
      font-size: 28px;
      text-align: center;
      color: #e4760b;
    }

    .btnDown {
      display: block;
      margin: 0 auto;
      margin-top: 40px;
      cursor: pointer;
    }
  }
}

.window-mask {
  width: 100%;
  height: 100%;
  background: #000;

  opacity: 0.75;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 101;
}

.popup {
  overflow-x: hidden;
  width: 1100px;
  height: 900px;
  border-radius: 20px;
  background: #fdf5e6;
  position: fixed;
  top: 57%;
  left: 50%;
  transform: translate(-50%; -50%);
  box-sizing: border-box;
  padding: 10px;
  box-shadow: 0px 0px 95px 0px rgba(43, 53, 105, 0.1);
  overflow-y: scroll;
  padding-bottom: 40px;
  z-index: 99999999999999;

  .guanbi1 {
    float: right;
    cursor: pointer;
    margin-top: 8px;
    margin-right: 10px;
  }

  .popContent {
    box-sizing: border-box;
    padding: 40px 50px 20px;

    li {
      font-size: 18px;
      color: #a86e4f;
      line-height: 36px;

      span {
        font-weight: 600;
        color: #e34a3f;
      }

      img {
        width: 100%;
        height: auto;
        margin: 20px 0;
      }

      table {
        border: 1px solid #e34a3f;
        border-radius: 15px;
        width: 100%;

        tr {
          th {
            text-align: center;
            font-weight: 500;
            line-height: 50px;
            border-bottom: 1px solid #d2a765;
            border-right: 1px solid #d2a765;
            padding: 5px;
          }

          th:last-child {
            border-right: 1px solid transparent;
          }
        }

        tr:nth-child(1) {
          th {
            font-weight: 600;
          }
        }
      }
    }
  }
}
</style>
